(function() {
    angular.module("EntrakV5").controller("calendarController", calendarController);

    function calendarController($scope, $rootScope, Service, KEY, Api) {
        console.log("calendarController");

        var caller = Api.createApiCaller();
        var nameSort = Service.getSorter();
        $rootScope.title = Service.translate("calendar.title");
        
        $scope.specialDays = [];
        $scope.holidayMap = {};    //by city id
        $scope.selectedCityId = null;

    /* first load */
        $scope.specialDayData = {
            id: null,
            selectedDates: [],
            holidays: [],
            loading: 0,
            saving: false,
            // addedDates(return): [timestamp]
            // removedDates(return): [{ id timestamp }]
        }

        $rootScope.loadingPage = 1;
        caller.call(Api.getSpecialDays()).then(function(res) {
            res.sort(nameSort);
            $scope.specialDays = res;
            $rootScope.loadingPage--;
        }, function(err) {
            if (err === KEY.ignore)
                return;
            $rootScope.generalErrorHandler();
        });

        //update holidays display
        $scope.onYearChanged = function(year){
            $rootScope.loadingPage++;
            $scope.specialDayData.loading++;
            caller.call(Api.getHolidays(year)).then(function(res) {
                $scope.holidayMap = {};
                var cities = res.map(function(item){
                    $scope.holidayMap[item.city.id] = item.holidays;
                    return item.city;
                });
                if ($scope.cityDropdown.dataSource.data().length == 0){
                    $scope.cityDropdown.setDataSource(new kendo.data.DataSource({
                        data: cities
                    }));
                }
                if (!$scope.selectedCityId){
                    $scope.selectedCityId = cities[0].id;
                    $scope.specialDayData.holidays = $scope.holidayMap[cities[0].id];
                } else {
                    $scope.specialDayData.holidays = $scope.holidayMap[$scope.selectedCityId];
                }

                $rootScope.loadingPage--;
                $scope.specialDayData.loading--;
            }, function(err) {
                if (err === KEY.ignore)
                    return;
                $scope.specialDayData.loading--;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.cityDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataValueField: "id",
            template: "#:data.country#/#:data.city#",
            valueTemplate: "#if (data && data.city){##:data.country#/#:data.city##}#",
            change: function(){
                $scope.$apply(function(){
                    $scope.specialDayData.holidays = $scope.holidayMap[$scope.selectedCityId];
                });
            }
        }

        $scope.onSelectSpecialDay = function(day){
            if (day.id && day.id !== $scope.specialDayData.id){
                $scope.specialDayData.id = day.id;
                $scope.specialDayData.name = day.name;
                $rootScope.loadingPage++;
                $scope.specialDayData.loading++;
                caller.call(Api.getSpecialDay(day.id)).then(function(res) {
                    $scope.specialDayData.selectedDates = res.dates;
                    $scope.specialDayData.initCalendar();
                    $rootScope.loadingPage--;
                    $scope.specialDayData.loading--;
                }, function(err) {
                    if (err === KEY.ignore)
                        return;
                    $scope.specialDayData.loading--;
                    $rootScope.generalErrorHandler();
                });
            }
        }

        $scope.addSpecialDay = function(){
            $scope.specialDayData.id = true;
            $scope.specialDayData.name = '';
        }

        $scope.saveSpecialDay = function(){
            $scope.specialDayData.saving = true;
            var name = $scope.specialDayData.name.trim();
            if ($scope.specialDayData.id === true){
                var result = caller.call(Api.createSpecialDay(name, $scope.specialDayData.addedDates));
            } else {
                var removedIds = $scope.specialDayData.removedDates.map(function(d){
                    return d.id;
                });
                var result = caller.call(Api.updateSpecialDay($scope.specialDayData.id, name, $scope.specialDayData.addedDates, removedIds));
            }
            result.then(function(res){
                Service.replaceArrItem($scope.specialDays, res, true);
                $scope.specialDays.sort(nameSort);
                $scope.cancelSpecialDay();
                $scope.specialDayData.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.specialDayData.saving = false;
                $rootScope.generalErrorHandler(true);
            });
        }

        $scope.deleteSpecialDay = function(){
            $rootScope.deletePopup.show("calendar.deleteSpDayTitle", "calendar.deleteSpDayDesc", $scope.specialDayData.name, function(){
                var id = $scope.specialDayData.id;
                caller.call(Api.deleteSpecialDay(id)).then(function(){
                    $rootScope.deletePopup.close();
                    Service.deleteArrItem($scope.specialDays, id);
                    $scope.cancelSpecialDay();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelItemFail"));
                });
            });
        }
        $scope.cancelSpecialDay = function(){
            $scope.specialDayData.id = null;
            $scope.specialDayData.selectedDates = [];
            $scope.specialDayData.initCalendar();
        }
    /* first load */

        $scope.$on("$destroy", function() {
            console.log("calendarController destroy");
            caller.cancel();
        });
    }
})();
